@import '../../../../variable.scss';
.validi-main-layout{
    &.validi-add-detox-layout{
        .validi-medicine-wrapper{
            form{
                button.close{
                    border:0px !important;
                    font-size: 16px !important;
                }
                .form-group{
                    .validi-slot-timing-wrapper{
                        display: flex;
                        margin-top: 11px;
                        flex-wrap: wrap;
                        margin-bottom: 30px;
                        margin: 10px 0;
                        .validi-checkbox-btnbox{
                            width: 50% !important;
                            vertical-align: middle;
                        }
                        input{
                            flex: 1;
                        }
                    }
                    .validi-finished-date-block{
                        display: flex;
                        align-items: center;
                        // .css-2b097c-container{
                        //     width: 50% !important;
                        // }
                        // .validi-radio-btnbox{
                        //     flex: 1;
                        // margin-left: 15px;
                        // }
                        input{
                            width: 100% !important;
                        }
                    }
                    .validi-create-plan{
                        margin-top: 10px;
                        button,a{
                            font-size: 15px;
                            color: $green;
                            transition: all 0.3s ease-in-out;
                            font-weight: 500;
                            font-style: italic;
                            text-decoration: underline !important;
                            &:hover{
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
            .validi-reduction-plan-block{
                margin-top: 35px;
                border-top: 1px solid $border-color;
                padding-top: 45px;
                .validi-reduction-plan-table{
                    padding: 20px;
                    border: 1px solid $border-color;
                    margin-bottom: 40px;
                    .validi-tabbar-inner-header{
                        margin-bottom: 0;
                    }
                    .validi-table-data-wrapper{
                        table{
                            tbody{
                                tr{
                                    th{
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                }
                .validi-progress-barblock{
                    .validi-progress-details{
                        background: $userbg-color;
                        border-radius: 4px;
                        padding: 25px 20px;
                        margin-bottom: 20px;
                        .validi-subtitle{
                            margin-bottom: 15px;
                        }
                    }
                }
            }
        }
    }
}