@import '../../../variable.scss';
.validi-main-layout{
    &.validi-detox-medicine-layout{
        .validi-medicine-wrapper{
            .validi-tabbar-wrapper{
                .validi-detox-dispense-block{
                    .validi-table-data-wrapper{
                        table{
                            tbody{
                                tr{
                                    th{
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                    }
                    .validi-detox-dispense-header{
                        display: flex;
                        margin-bottom: 21px;
                        .validi-radio-btnbox{
                            margin-right: 30px;
                        }
                    }
                }
            }
        }
    }
}

