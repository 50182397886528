@import '../../../variable.scss';
.validi-footer-bar-main{
    padding: 15px 0;
    background: $white;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.19);
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    display: none;
    @include media-breakpoint-down(md){
        display: block;
    }
    .validi-footer-iconbar-main{
        display: flex;
        justify-content: space-between;
        .validi-footer-icon{
            flex: 0 0 20%;
            max-width: 20%;
            text-align: center;
            display: inline-flex;
            .validi-footer-icon-list{
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: $grey;
                transition: all 0.3s ease-in-out;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin: auto;
                i{
                    width: 24px;
                    height: 24px;
                    overflow: hidden;
                    margin-bottom: 5px;
                    transition: all 0.3s ease-in-out;
                    svg{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        object-position: center;
                        display: block;
                        transition: all 0.3s ease-in-out;
                    }
                }
                &.active{
                    color: $green;
                    i{
                        svg{
                            g{
                                fill: $green;
                                transition: all 0.3s ease-in-out;
                            }
                        }
                    }
                }
                &:hover{
                    color: $green;
                    i{
                        svg{
                            g{
                                fill: $green;
                            }
                        }
                    }
                }
            }
        }
    }
}