@import '../../../variable.scss';
.validi-login-layout-main{
    width: 100%;
    min-height: 100vh;
    background: $green;
    height: 100%;
    display: flex;
    @include media-breakpoint-down(md){
        position: relative;
    }
    .validi-login-form-wrapper{
        background: $white;
        padding: 40px;
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.19);
        border-radius: 5px;
        width: 500px;
        max-width: 100%;
        margin: auto;
        @include media-breakpoint-down(md){
            position: relative;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            border-radius: 15px 15px 0 0;
        }
        @include media-breakpoint-down(sm){
            padding: 40px 20px;
        }
        .validi-logo-block{
            text-align: center;
            margin-bottom: 30px;
            img{
                width: 80px;
                height: 60px;
                object-fit: contain;
                object-position: center;
                margin-bottom: 8px;
            }
            .main-title{
                margin-bottom: 0;
                font-size: 21px;
                font-weight: 800;
                color: #000;
            }
        }
        form{
            .form-group{
                margin-bottom: 21px;
                @include media-breakpoint-down(md){
                    margin-bottom: 18px;  
                }
                @include media-breakpoint-down(sm){
                    margin-bottom: 15px;  
                }
                label{
                    font-weight: 500;
                    font-size: 14px;
                    color: $lightgrey;
                    margin-bottom: 5px;
                }
                .form-control{
                    border: 1px solid $border-color;
                    border-radius: 3px;
                    padding: 14px;
                    background: $white;
                    outline: none;
                    box-shadow: none;
                    font-size: 15px;
                    color: $black;
                    font-weight: 600;
                    @include media-breakpoint-down(sm){
                        font-size: 14px;
                        padding: 12px 14px;
                    }
                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $lightgrey;
                        font-weight: normal;
                    }
                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $lightgrey;
                        font-weight: normal;
                    }
                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $lightgrey;
                        font-weight: normal;
                    }
                    &:-moz-placeholder { /* Firefox 18- */
                        color: $lightgrey;
                        font-weight: normal;
                    }
                }
            }
            .forgot-link{
                font-size: 15px;
                color: $black;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 25px;
                text-decoration: underline !important;
                font-style: italic;
                font-weight: 500;
            }
            .validi-theme-btn{
                margin-top: 10px;
                width: 100%;
                padding: 14px 18px;
                text-transform: uppercase;
                font-weight: 700;
            }
        }
    }
}