@import '../../variable.scss';
.validi-main-layout {
    .validi-tabbar-wrapper{
        .validi-journal-msg-wrapper{
            padding: 20px 15px 15px 25px;
            .validi-user-journal-msg{
                border-bottom: 1px solid $border-color;
                margin-bottom: 25px;
                padding-bottom: 15px;
                .validi-subtitle{
                    font-size: 14px;
                    margin-bottom: 4px;
                }
                .validi-paragraph{
                    font-size: 13px;
                }
                .validi-avatar-details-wrepper{
                    margin-bottom: 15px;
                    .validi-avatar-img{
                        width: 46px;
                        height: 46px;
                    }
                }
            }
        }
    }
}