@import '../../../variable.scss';
.validi-header-bar-main{
    padding: 15px 0;
    border-bottom: 1px solid $border-color;
    background: $white;
    .navbar{
        padding: 0;
        .navbar-brand{
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0;
            img{
                width: 55px;
                height: 55px;
                object-fit: contain;
                object-position: center;
                margin-right: 15px;
                @include media-breakpoint-down(lg){
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                }
                @include media-breakpoint-down(md){
                    width: 45px;
                    height: 45px;
                }
            }
            .main-title{
                margin-bottom: 0;
                font-size: 21px;
                font-weight: 800;
                color: #000;
                @include media-breakpoint-down(lg){
                    font-size: 19px; 
                }
                @include media-breakpoint-down(md){
                    font-size: 18px;
                }
            }
        }
        .validi-header-menu-wrapper{
            justify-content: flex-end;
            display: flex;
            align-items: center;
            .validi-header-menu-list{
                display: block;
                @include media-breakpoint-down(md){
                    display: none;   
                }
                .nav-link{
                    font-size: 14px;
                    color: $black;
                    font-weight: 600;
                    padding: 0;
                    transition: all 0.3s ease-in-out;
                    margin: 0 15px;
                    position: relative;
                    display: inline-block;
                    @include media-breakpoint-down(lg){
                        margin: 0 9px;
                    }
                    &::before {
                        position: absolute;
                        content: '';
                        right: 0;
                        left: 0;
                        bottom: -33px;
                        background: $green;
                        width: 100%;
                        height: 3px;
                        opacity: 0;
                        transition: all 0.3s ease-in-out;
                    }
                    &.menu-active{
                        color: $green;
                        &::before {
                            opacity: 1;
                        }
                    }
                    &:hover{
                        color: $green;
                        &::before {
                            opacity: 1;
                        }
                    }
                }  
            }
            .validi-avatar-wrapper{
                display: flex;
                align-items: center;
                .validi-avatar-details-wrepper{
                    .validi-avatar-img{
                        width: 46px;
                        height: 46px;
                        background: #f2f2f2;
                    }
                    .validi-avatar-details{
                        margin-left: 10px;
                        h5{
                            margin-bottom: 4px;
                            font-size: 14px;
                        }
                        p{
                            font-size: 12px;
                        }
                    }
                }
                .validi-user-login-block{
                    .nav-item{
                        .nav-link{
                            margin-right: 0px;
                            font-size: 14px;
                            color: $black;
                            font-weight: 600;
                            padding: 10px;
                            &::before{
                                content: unset;
                            }
                        }
                        .dropdown-menu{
                            right: 0;
                            left: auto;
                            padding: 0;
                            min-width: 120px;
                            border: 1px solid #EFEFEF;
                            box-shadow: 0 5px 14px 0 rgba(0,0,0,0.11);
                            border-radius: 4px;
                            background: $white;
                            .validi-dropdown-menu{
                                display: flex;
                                flex-direction: column;
                                .nav-link{
                                    padding: 12px;
                                    margin: 0;
                                    background: $white;
                                    &.logout{
                                        color: #FE2525;
                                        &:hover{
                                            background: #FE2525;
                                            color: $white;
                                        }
                                    }
                                    &:hover{
                                        background: $green;
                                        color: $white;
                                    }
                                }
                            }  
                        }
                    }
                }
            }
            .validi-login-menu-wrapper{
                a{
                    font-size: 15px;
                    color: $white;
                    font-weight: 600;
                    text-transform: uppercase;
                    background: $green;
                    padding: 12px 15px;
                    border-radius: 5px;
                    min-width: 100px;
                    display: flex;
                    justify-content: center;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }
}

