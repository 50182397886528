@import '../../../variable.scss';
.validi-login-layout-main{
    &.validi-forget-layout{
        .validi-forget-details{
            margin-bottom: 30px;
            h4{
                font-size: 21px;
                color: $black;
                font-weight: 800;
                margin-bottom: 9px;
                @include media-breakpoint-down(sm){
                    font-size: 18px;
                }
            }
            p{
                font-size: 16px;
                color: $black;
                font-weight: normal;
                @include media-breakpoint-down(sm){
                    font-size: 16px;
                }
            }
        }
        form{
            .form-group {
                margin-bottom: 35px;
                @include media-breakpoint-down(sm){
                    margin-bottom: 25px;
                }
            }
        }
    }
}