@import '../../../../variable.scss';
.validi-main-layout{
    &.validi-add-detox-layout{
        .validi-medicine-wrapper{
            .validi-reduction-plan-block{
                margin-top: 35px;
                border-top: 1px solid $border-color;
                padding-top: 45px;
                .validi-reduction-plan-table{
                    padding: 20px;
                    border: 1px solid $border-color;
                    margin-bottom: 40px;
                    .validi-tabbar-inner-header{
                        margin-bottom: 0;
                    }
                    .validi-table-data-wrapper{
                        table{
                            tbody{
                                tr{
                                    th{
                                        vertical-align: middle;
                                    }
                                }
                            }
                        }
                        .validi-mg-text span{
                            cursor: pointer;
                        }
                        .dosage-textbox{
                            border: 1px solid $border-color;
                            width:45px;
                            text-align: center;
                            padding: 5px 5px 2px 5px;
                        }
                        .dosage-textbox:focus,.dosage-textbox:focus-visible{
                            border: 1px solid $border-color !important;
                        }   
                    }
                }
            }
            .suspended-image{
                position: absolute;
                left: 31%;
                top: 50%;
                background-image: url("../../../../images//suspened.png");
                opacity: 0.4;
            }
        }
    }
}