@import '../../variable.scss';

.validi-main-layout{
    .validi-tabbar-wrapper{
        .validi-header-goal{
            background-color: $green;
            color:$white;
        }
        .accordion-button{
            color:$black;
            font-weight: 500;
        }
        .accordion-button:not(.collapsed){
            background-color: $green;
            color:$white;
        }
        .border-success{
            border-color:$green !important;
        }
        .validi-part-goals-wrapper{
            padding-top: 40px;
            border-top: 1px solid $border-color;
            margin-top: 50px;
            .accordion-item{
                .accordion-button:not(.collapsed){
                    background-color:$green !important;
                    color: $white;    
                    font-weight: 500;
                }   
            }
            .nav{
                margin-top: 25px;
            }
            .validi-complete-goal-footer{
                padding-top: 25px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
