@import '../../variable.scss';
.validi-main-layout{
    &.validi-clinets-layout{
        .validi-our-clinets-details{
            .infinite-scroll-component {
                overflow: hidden !important;
            }
            .validi-our-clients-list{
                padding: 12px 15px;
                border: 1px solid $border-color;
                background: $white;
                display: block;
                width: 100%;
                transition: all 0.3s ease-in-out;
                margin-bottom: 21px;
                &:hover{
                    background: $userbg-color;
                    opacity: 1;
                }
                .validi-avatar-img{
                    width: 70px;
                    height: 70px;
                }
            }
            .table-head .col-md-6,.table-foot .col-md-6{
                margin: 4px 0px !important;
                @include media-breakpoint-down(md){
                    text-align: center;
                    .input-group{
                        display: block;
                    }
                    .pagination{
                        justify-content:center !important;
                    }
                }
            }            
            .btn-outline-secondary:hover {
                background-color: #fff !important;
            }
            .cpr-number{
                font-size: 12px;
                color:#888;
            }
            
            table#as-react-datatable{
                color: #fff !important;
                border-width: 1px solid !important;
            }
            .table > :first-child{
                border:1px solid $border-color !important;
            }
            .table > :not(caption) > * > *{
                padding: 0px !important;
            }
        }
    }
}
.popover{
    max-width: 25% !important;
}