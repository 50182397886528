@import '../../../variable.scss';
.validi-main-layout{
    &.validi-pn-plan-layout-main{
         .validi-medicine-wrapper{
             .validi-tabbar-wrapper{
                 .tab-content{
                     .validi-table-data-wrapper{
                        table{
                            tbody{
                                tr{
                                    td{
                                        vertical-align: middle;
                                        .validi-avatar-details-wrepper{
                                            min-width: 200px;
                                            .validi-avatar-img{
                                                width: 37px;
                                                height: 37px;
                                            }
                                            .validi-avatar-details{
                                                margin-left: 10px;
                                                .validi-user-main-title{
                                                    font-size: 14px;
                                                    margin-bottom: 4px;
                                                }
                                                .validi-paragraph{
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                        &.user-note{
                                            min-width: 200px;
                                        }
                                    }
                                }
                            }
                        }
                     }
                 }
             }
         }
        
    }
}   
