$black: #000;
$white:#fff;
$green:#66BB6A;
$lightgrey: #565353;
$border-color: #66606045;
$userbg-color: #FBFBFB;
$grey: #6F6F6F;

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";