@import '../../variable.scss';
.validi-main-layout{
    padding: 60px 0 130px;
    .validi-medicine-wrapper{
        padding: 20px 25px;
        background: $white;
        border: 1px solid $border-color;
        @include media-breakpoint-down(md){
            padding: 15px;
        }
        .validi-inner-page-link{
            padding: 20px;
            background: #FBFBFB;
            border: 1px solid $border-color;
            width: 100%;
            height: 206px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 18px;
            color: $lightgrey;
            font-weight: 500;
            margin-bottom: 15px;
            &.active{
                color: $white;
                background: $green;
            }
        }
    }
}