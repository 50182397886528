@import '../../../variable.scss';
.validi-main-layout{
    &.validi-message-layout{
        .validi-medicine-wrapper{
            .validi-inner-header-bar{
                margin-bottom: 25px;
                border-bottom: 1px solid $border-color;
                padding-bottom: 25px;
            }
            .validi-tabbar-wrapper{
                .validi-tabbar-inner-header{
                    .validi-subtitle{
                        margin-bottom: 20px;
                    }
                }
            }
        }
    } 
}